/***************************

global variables

***************************/

let activeClass = 'c-navigation__menu-item-parent--active';
let solidBannerClass = 's-navigation-banner--solid';
let navBanner,
    nav,
    navMobile,
    menuLinks,
    observerSections,
    logo,
    navHelper,
    introSection,
    hamburger,
    mobileMenu,
    footerDate;
let isLinkClicked = false; // flag to ensure Intersection Observer won't get triggered upon link clicks

/***************************

nav links - toggling active states on click and scroll

***************************/

const scrollAnimation = function (destination) {
    window.scrollTo({
        top: destination,
        left: 0,
        behavior: 'smooth',
    });
};

const handleActiveLinks = function (targetEl) {
    if (!targetEl) return;

    if (
        !navBanner.classList.contains(solidBannerClass) &&
        targetEl.getAttribute('href') !== '#home'
    ) {
        navBanner.classList.add(solidBannerClass);
    }

    const allLinks = [...document.querySelectorAll('.js-menu-link')];
    allLinks.forEach((el) => {
        el.parentElement.classList.remove(activeClass);
    });

    allLinks
        .filter(
            (link) =>
                link.getAttribute('href') === targetEl.getAttribute('href')
        )
        .forEach((el) => el.parentElement.classList.add(activeClass));

    mobileMenu.classList.remove('s-navigation-slide--visible');
    hamburger.classList.remove('btn__menu--active');
};

const navScrollUpdate = function (entries) {
    const entry = entries[0];

    if (entry.isIntersecting && !isLinkClicked) {
        const relevantMenuLink = nav.querySelector(
            `[href='${'#' + entry.target.id}']`
        );
        handleActiveLinks(relevantMenuLink);
    }
};

const initLinksSectionObservers = function (sections) {
    const sectionObserver = new IntersectionObserver(navScrollUpdate, {
        root: null,
        threshold: 1,
    });

    sections.forEach((section) => {
        sectionObserver.observe(section);
    });
};

const checkNav = function (e) {
    const closestLink = e.target.closest('a'); // find the closest link
    if (!closestLink) return;

    console.log('hi');

    e.preventDefault();
    isLinkClicked = true;

    const sectionID = '' + closestLink.getAttribute('href');

    let relevantSectionOffset =
        document.querySelector(sectionID).getBoundingClientRect().top +
        window.scrollY -
        120;

    if (sectionID === '#home') {
        relevantSectionOffset = 0;
    }

    if (closestLink.parentElement.classList.contains(activeClass)) {
        // prevents default behaviour when the link is active already
        e.preventDefault();
        isLinkClicked = false;
        return;
    }

    // scroll to the relevant section
    scrollAnimation(relevantSectionOffset);

    // remove and add active links
    handleActiveLinks(e.target);

    setTimeout(() => {
        isLinkClicked = false;
    }, 1000);
};

/***************************

Navigation - toggle solid state

***************************/

const navBannerUpdate = function (entries) {
    const entry = entries[0];
    if (entry.isIntersecting) {
        navBanner.classList.add(solidBannerClass);
    }
};

const navBannerUpdate2 = function (entries) {
    const entry = entries[0];
    if (
        entry.isIntersecting &&
        navBanner.classList.contains(solidBannerClass)
    ) {
        navBanner.classList.remove(solidBannerClass);
    }
};

const initBannerSectionObserver = function (section) {
    const bannerObserver = new IntersectionObserver(navBannerUpdate, {
        root: null,
        threshold: 0.1,
    });

    bannerObserver.observe(section);
};

const initBannerSectionObserver2 = function (section) {
    const bannerObserver = new IntersectionObserver(navBannerUpdate2, {
        root: null,
        threshold: 0.9,
    });

    bannerObserver.observe(section);
};

/***************************

Navigation - toggle mobile nav

***************************/

const toggleMobileNav = function (e) {
    menuLinks = document.querySelectorAll('.js-mobile-menu .js-menu-link');

    if (!mobileMenu.classList.contains('s-navigation-slide--visible')) {
        mobileMenu.classList.add('s-navigation-slide--visible');
        e.target.closest('button').classList.add('btn__menu--active');
    } else {
        mobileMenu.classList.remove('s-navigation-slide--visible');
        e.target.closest('button').classList.remove('btn__menu--active');
    }
};

/***************************

Navigation - check initial window scroll to check if nav needs to be solid

***************************/

const checkInitialScroll = function () {
    if (window.scrollY > 0) {
        navBanner.classList.add(solidBannerClass);
    }
};

/***************************

Logo - smooth scroll

***************************/

const logoScroll = function (e) {
    e.preventDefault();
    isLinkClicked = true;
    scrollAnimation(top);
    handleActiveLinks(menuLinks[0]);
    setTimeout(() => {
        isLinkClicked = false;
    }, 1000);
};

/***************************

Dynamically insert footer date

***************************/

const insertFooterDate = function () {
    footerDate.textContent = new Date().getFullYear();
};

/***************************

Document - load event

***************************/
window.addEventListener('DOMContentLoaded', (e) => {
    navBanner = document.querySelector('.js-navigation-banner');
    nav = document.querySelector('.js-navigation');
    navMobile = document.querySelector('.js-navigation-mobile');
    menuLinks = nav.querySelectorAll('.js-menu-link');
    introSection = document.querySelector('.js-intro');
    observerSections = document.querySelectorAll('.js-section-observer');
    navHelper = document.querySelector('.js-nav-helper');
    hamburger = document.querySelector('.js-navigation-mobile-btn');
    mobileMenu = document.querySelector('.js-mobile-menu');
    logo = document.querySelector('.js-logo');
    footerDate = document.querySelector('.js-footer-date');

    insertFooterDate();

    checkInitialScroll();

    [nav, navMobile].forEach((n) =>
        n.addEventListener('click', (e) => checkNav(e))
    );
    logo.addEventListener('click', (e) => logoScroll(e));

    hamburger.addEventListener('click', (e) => toggleMobileNav(e));

    initLinksSectionObservers(observerSections);
    initBannerSectionObserver(introSection);
    initBannerSectionObserver2(navHelper);
});
